<template>
<v-container class="mt-10 my-2 mx-2 px-2 col-md-12">
  <div>
    <v-col cols="12">
      <v-text-field label="name" v-model="name" required></v-text-field>
    </v-col>
    <v-col cols="12">
      <v-text-field label="link" v-model="link" required></v-text-field>
    </v-col>
    <v-col cols="12">
      <v-btn color="blue darken-1" text @click="addSc"> Save </v-btn></v-col
    >
  </div>
</v-container>
</template>
<script>
export default {
    data(){
        return{
            name: '',
            link:'',
            tkn: "Bearer ".concat(this.$store.getters.getToken),
        }
    },
    methods:{
        addSc:function () {
              this.$http
        .post(
          "letter/upfake/"+this.$route.params.id,
          {
           "text": this.link,
          "namepage": this.name
          },
          {
            headers: {
              Authorization: this.tkn,
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.text = "";
          alert(res.data.message);
          this.$router.push({name:"users"})
        })
        .catch((err) => {
          alert(err.message);
        });
        }
    },
    mounted(){
        this.$http
      .get("/letter/tosimple/"+this.$route.params.id)
      .then((res) => {
        this.link = res.data.text;
        this.name = res.data.namepage;
       
      })
      .catch((err) => {
        this.$notify({
          group: "errors",
          type: "error",
          title: "error",
          text: err,
        });
      });
    }
}
</script>